<template>
    <div>
        <BT-Fast-Start-Banner />
        <BT-Blade-Items
            actionsWidth="100"
            :items="newItems"
            canDelete
            :canRefresh="false"
            :canSelect="false"
            :headers="[{ text: 'Product Name', value: 'productName' },
                    { text: 'Abbreviation', value: 'abbreviation' },
                    { text: 'Public', value: 'isPublicProduct', bool: true },
                    { text: 'Public Description', value: 'publicDescription' },
                    { text: 'Sort Number', value: 'sortNumber' },
                    { text: 'Tax Type', value: 'taxType' },
                    { text: 'Sellable', value: 'isSold', bool: true },
                    { text: 'Buyable', value: 'isAcquired', bool: true },
                    { text: 'Manufactured', value: 'isManufactured', bool: true },
                    { text: 'Perishable', value: 'isPerishable', bool: true },
                    { text: 'Expiry Days', value: 'expiryDays' },
                    { text: 'Returnable', value: 'isReturnable', bool: true },
                    { text: 'Temp Sensitive', value: 'isTemperatureSensitive', bool: true },
                    { text: 'Infinite', value: 'isInfinite', bool: true },
                    { text: 'Dimensions', value: 'dimensions', isDisplay: true }]"
            :hideFooter="false"
            :loadingMsg="loadingMsg"
            :onEdit="editProduct"
            :canCreate="false"
            :onDelete="deleteProduct"
            title="New Products">

            <template v-slot:actions>
                <BT-Dialog 
                    v-model="newProduct"
                    :show="showDialog"
                    label="New Product"
                    :loadingMsg="loadingMsg"
                    @ok="addOrUpdateProduct"
                    @close="closeDialog"
                    width="450"
                    small
                    title="Add Product">
                    <template v-slot="{ item }">

                        <BT-Field-String
                            v-model="item.productName"
                            label="Product Name"
                            isEditing />
                        
                        <BT-Field-String
                            v-model="item.abbreviation"
                            label="Abbreviation"
                            isEditing />

                        <BT-Field-Number
                            v-model.number="item.sortNumber"
                            label="Sort Number"
                            isEditing />

                        <BT-Field-Checkbox
                            isEditing
                            label="Publicly Viewable"
                            v-model="item.isPublicProduct" />
                        
                        <v-slide-x-transition>
                            <BT-Field-String
                                v-if="item.isPublicProduct"
                                isEditing
                                label="Public Description"
                                v-model="item.publicDescription" />
                        </v-slide-x-transition>

                        <v-list-item>
                            <v-list-item-content>
                                <BT-Tax-Type v-model="item.taxType" />
                            </v-list-item-content>
                        </v-list-item>

                        <BT-Field-Checkbox
                            isEditing
                            label="I Sell This Product"
                            v-model="item.isSold" />

                        <BT-Field-Checkbox
                            isEditing
                            label="I Buy This Product"
                            v-model="item.isAcquired" />

                        <BT-Field-Checkbox
                            isEditing
                            label="I Manufacture This Product"
                            v-model="item.isManufactured" />

                        <BT-Field-Checkbox
                            isEditing
                            label="This Product Is Perishable"
                            v-model="item.isPerishable" />

                        <v-slide-x-transition>
                            <BT-Field-Number
                                v-if="item.isPerishable"
                                isEditing
                                label="Days Till Expiry"
                                v-model.number="item.expiryDays" />
                        </v-slide-x-transition>
                        
                        <BT-Field-Checkbox
                            isEditing
                            label="This Product Needs Refrigeration"
                            v-model="item.isTemperatureSensitive" />

                        <v-divider class="my-2" />

                        <v-subheader>Dimensions</v-subheader>

                        <BT-Field-Number
                            v-model.number="item.length"
                            label="Length (mm)"
                            isEditing />
                        
                        <BT-Field-Number
                            v-model.number="item.width"
                            label="Width (mm)"
                            isEditing />
                        
                        <BT-Field-Number
                            v-model.number="item.height"
                            label="Height (mm)"
                            isEditing />
                        
                        <BT-Field-Number
                            v-model.number="item.volume"
                            label="Volume (Ltr)"
                            isEditing />

                        <BT-Field-Number
                            v-model.number="item.weight"
                            label="Weight (Kg)"
                            isEditing />

                    </template>
                </BT-Dialog>

                <BT-File-Select 
                    @fileSelected="importItems"
                    inline
                    label="Import"
                    icon="mdi-file-import-outline"
                    small
                    title="Import CSV" />
                
                <BT-Btn
                    @click="generateProductCSVFile"
                    icon="mdi-file-delimited-outline"
                    inline
                    label="Get CSV Template"
                    small />
                
            </template>
            <template v-slot:actionsRight>
                <v-btn @click="saveValidItems" class="primary" small>Save {{ itemsReadyToSave.length }} Valid Items</v-btn>
            </template>
            <template v-slot:itemActions="{ item }">
                <v-icon 
                    v-if="productExists(item.productName)"
                    class="error--text"
                    small
                    title="Product already exists">mdi-alert</v-icon>
                <v-btn
                    icon
                    small>
                    <v-icon
                        v-if="item.productName == null"
                        color="warning"
                        small
                        @click="editProduct(item)">mdi-alert-outline</v-icon>
                </v-btn>
            </template>
        </BT-Blade-Items>
        <v-alert v-model="showError" dismissible type="error">{{ errorMessage }}</v-alert>
        <v-alert v-model="showAlert" dismissible type="success">Saved</v-alert>     
        <BT-Snack v-model="msg" />
        <v-overlay v-model="showImport" absolute class="text-center" :opacity="0.8">
            <v-row no-gutters>
                <BT-File-Select
                    @click="showImport = false"
                    @fileSelected="importItems"
                    label="Import"
                    large
                    icon="mdi-file-import-outline"
                    title="Import CSV" />
                
                <BT-Btn
                    @click="generateProductCSVFile"
                    icon="mdi-file-delimited-outline"
                    label="Get CSV Template"
                    large />
            </v-row>
        </v-overlay>
    </div>
    
</template>

<script>
export default {
    name: 'Product-New',
    components: {        
        BTDialog: () => import('~components/BT-Dialog.vue'),
        BTFastStartBanner: () => import('~components/BT-Fast-Start-Banner.vue'),
        BTFileSelect: () => import('~components/BT-File-Select.vue'),
    },
    data: function() {
        return {
            showDialog: false,
            loadingMsg: null,
            newProduct: this.getEmptyProduct(),
            newItems: [],
            showError: false,
            showAlert: false,
            showImport: false,
            errorMessage: null,
            position: null,
            msg: null,
            existingProducts: []
        }
    },
    async mounted() {
        if (this.$route.params && this.$route.params.products) {
            this.newItems = this.$route.params.products;
        }

        this.existingProducts = await this.$BlitzIt.store.getAll('products', { properties: 'ID,ProductName,Abbreviation' });

        var q = this.$route.query;
        if (q) {
            if (q.create != null) {
                this.showDialog = true;
            }
            else if (q.importCSV != null) {
                this.showImport = true;
            }
        }
    },
    computed: {
        itemsReadyToSave() {
            return this.newItems.filter(y => y.productName != null);
        }
    },
    methods: {
        productExists(productName) {
            return this.existingProducts.some(x => x.productName == productName);
        },
        deleteProduct(product) {
            var ind = this.newItems.findIndex(y => y.productName == product.productName);
            if (ind >= 0) {
                this.newItems.splice(ind, 1);
            }
        },
        closeDialog() {
            this.showDialog = false;
        },
        editProduct(item) {
            this.newProduct = item;
            this.showDialog = true;
        },
        getEmptyProduct() {
            return {
                productName: null,
                abbreviation: null,
                isPublicProduct: false,
                publicDescription: null,
                sortNumber: 0,
                taxType: null,
                isSold: false,
                isAcquired: false,
                isManufactured: false,
                isPerishable: false,
                //expiryDays: null,
                isReturnable: false,
                isTemperatureSensitive: false,
                isInfinite: false,
                length: null,
                height: null,
                width: null,
                volume: null,
                weight: null
            };
        },
        addOrUpdateProduct(item) {
            this.showDialog = false;

            if (!this.newItems.some(x => x.productName == item.productName)) {
                this.newItems.push(item);
            }
            else {
                var ind = this.newItems.findIndex(x => x.productName == item.productName);

                this.newItems[ind] = item;
            }

            this.newProduct = this.getEmptyProduct();
        },
        CSVIsTrue(val) {
            return val != null && (val == 'true' || val == 'True' || val == 'Yes' || val == 'yes');
        },
        importItems(file) {
            this.showImport = false;
                var reader = new FileReader();
                
                reader.onload = (e) => {
                    var txt = e.target.result;
                    if (txt.length) {
                        var items = txt.split('\n');
                        var missed = 0;
                        var added = 0;
                        var failed = 0;

                        for (var i = 0; i < items.length; i++) {
                            var nItem = this.getEmptyProduct();

                            var nItemProps = items[i].split(',');

                            if (nItemProps.length > 0) {
                                nItem.productName = nItemProps[0];
                            }
                            if (nItemProps.length > 1) {
                                nItem.abbreviation = nItemProps[1];
                            }
                            if (nItemProps.length > 2) {
                                nItem.isPublicProduct = this.isTrue(nItemProps[2]);
                            }
                            if (nItemProps.length > 3) {
                                nItem.publicDescription = nItemProps[3];
                            }
                            if (nItemProps.length > 4) {
                                nItem.sortNumber = nItemProps[4];
                            }
                            if (nItemProps.length > 5) {
                                nItem.taxType = nItemProps[5];
                            }
                            if (nItemProps.length > 6) {
                                nItem.isSold = this.isTrue(nItemProps[6]);
                            }
                            if (nItemProps.length > 7) {
                                nItem.isAcquired = this.isTrue(nItemProps[7]);
                            }
                            if (nItemProps.length > 8) {
                                nItem.isManufactured = this.isTrue(nItemProps[8]);
                            }
                            if (nItemProps.length > 9) {
                                nItem.isPerishable = this.isTrue(nItemProps[9]);
                            }
                            if (nItemProps.length > 10) {
                                nItem.expiryDays = nItemProps[10];
                            }
                            if (nItemProps.length > 11) {
                                nItem.isTemperatureSensitive = this.isTrue(nItemProps[11]);
                            }
                            if (nItemProps.length > 12) {
                                nItem.length = nItemProps[12];
                            }
                            if (nItemProps.length > 13) {
                                nItem.width = nItemProps[13];
                            }
                            if (nItemProps.length > 14) {
                                nItem.height = nItemProps[14];
                            }
                            if (nItemProps.length > 15) {
                                nItem.weight = nItemProps[15];
                            }
                            if (nItemProps.length > 16) {
                                nItem.volume = nItemProps[16];
                            }
                            
                            if (nItem.productName != null && nItem.productName.length > 0 && nItem.productName != 'Product Name') {
                                if (nItem.productName == null) {
                                    failed++;
                                }
                                else {
                                    var found = false;
                                    for (var ii = 0; ii < this.newItems.length; ii++) {
                                        if (this.newItems[ii].productName === nItem.productName) {
                                            missed++;
                                            found = true;
                                        }
                                    }
                                    if (!found) {
                                        if (!this.productExists(nItem.productName)) {
                                            this.newItems.unshift(nItem);
                                            added++;
                                        }
                                    }
                                }
                            }
                        }
                        this.errMsg = added + ' added. ' + missed + ' missed. ' + failed + ' failed.';
                    }
                };

                reader.readAsText(file);

        },
        async saveValidItems() {
            try {
                this.loadingMsg = 'Saving Valid Items';
                var itemsToSave = this.newItems.filter(x => x.productName != null);

                for (let i = 0; i < itemsToSave.length; i++) {
                    const itemToSave = itemsToSave[i];
                    
                    await this.$BlitzIt.store.post('products', itemToSave);

                    var ind = this.newItems.findIndex(y => y.productName == itemToSave.productName);
                    if (ind >= 0) {
                        this.newItems.splice(ind, 1);
                    }
                }
            }
            catch (err) {
                this.errorMessage = this.extractErrorDescription(err);
                this.showError = true;
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async save(item) {
            this.loadingMsg = 'Saving Product';

            try {
                await this.$BlitzIt.store.post('products', item);
                var pInd = this.newItems.findIndex(y => y.productName == item.productName);
                if (pInd >= 0) {
                    this.newItems.splice(pInd, 1);
                }

                this.showDialog = false;
                this.msg = `${item.productName} created`;
            }
            catch (err) {
                this.errorMessage = this.extractErrorDescription(err);
                this.showError = true;
            }
            finally {
                this.loadingMsg = null;
                this.newProduct = this.getEmptyProduct();
            }
        }
    }
}
</script>